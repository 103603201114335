@import "./config.scss";
@import "./about.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");

#projects-container {
  @extend #about_container;
  background-color: rgb(245, 245, 245);
  padding: 1vh 0 14vh 0;

  #heading_container {
    @extend #main_heading_container;
    #heading {
      @extend #main_heading;
    }
    #heading_bar {
      @extend #main_heading_bar;
    }
  }

  .items {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .items span {
    padding: 7px 10px;
    margin: 0 10px;
    font-size: 13px;
    font-family: $primary-font-bold;
    font-weight: 500;
    font-weight: bold;
    letter-spacing: 0.01cm;
    cursor: pointer;
    color: $primary-font;
    background-color: transparent;
    border-radius: 0px;
    transition: all 0.3s ease;
    text-transform: uppercase;
  }

  .items span.active,
  .items span:hover {
    color: #fff;
    background: $secondary-font;
  }
  #img-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    width: 93%;
    margin: 8vh auto 0 auto;
    column-gap: 2em;
    row-gap: 2em;

    @media (max-width: 780px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  #img-wrap {
    overflow: hidden;
    opacity: 1;
    position: relative;
  }

  #img-wrap img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    // animation: project-image-animation 0.3s;
  }

  #img-wrap:hover #overlay {
    bottom: 0;
    height: 100%;
    opacity: 0.9;
  }

  #overlay {
    position: absolute;
    z-index: 5;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: rgb(244, 244, 244);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
  }

  #project-name {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-family: $primary-font-bold;
    font-weight: bolder;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }

  #project-technologies {
    margin-top: 5vh;
    @extend #project-name;
    font-family: $primary-font;
    font-weight: medium;
    font-size: 17px;
    color: $secondary-font;
  }

  #project-modal-btn {
    @extend #project-name;
    width: 40%;
    margin-top: 18vh;
    color: black;
    font-size: 13px;
    letter-spacing: 0.01cm;
    text-transform: uppercase;
    border: 1px solid $secondary-font;
    background-color: transparent;
    font-family: $primary-font;
    font-weight: light;
    padding: 15px;
    &:hover {
      cursor: pointer;
      background-color: $secondary-font;
      color: white;
      transition: 0.3s;
    }
  }

  #backdrop {
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: rgba(37 42 52 / 1);
    z-index: 10;
  }

  .close-icon {
    border: 1px solid white;
    color: white;
    cursor: pointer;
    float: right;
    font-size: 25pt;
    margin: 8px;
    padding: 5px 5px 0px 5px;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.2s linear;
    z-index: 100;
    &:hover {
      background-color: $secondary-font;
      border: none;
    }
  }

  #backdrop img {
    @include modal-design();
    margin-top: 10px;
    vertical-align: middle;

    @media (max-width: 800px) {
      margin-top: 100px;
      width: 100%;
      padding: 0 0.5rem;
    }
    @media (max-width: 580px) {
      margin-top: 200px;
    }
  }

  #selected-image-data {
    @include modal-design();
    // background-color: rgba(255 255 255/ 1);
    margin: 1rem auto 1rem auto;
    padding: 0.5rem 0 1.7rem 0;
    color: white;
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.01cm;

    @media (max-width: 800px) {
      width: 100%;
      padding: 0em 0.8rem;
    }
    // box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    #selected-project-name {
      font-family: $primary-font;
      font-size: 18pt;
      font-weight: bold;
      margin-bottom: 20px;
    }
    #selected-project-des {
      font-size: 11pt;
      font-weight: bold;
      margin-bottom: 20px;
      color: rgb(150, 150, 150);
      text-align: justify;
    }
    #selected-project-headings {
      font-size: 11pt;
      display: inline;
      padding: 0 0.2em;
      margin: 20px 0;
      font-weight: bolder;
      display: inline;
      margin-bottom: 15px;
    }
    #selected-project-technology {
      font-size: 11pt;
      display: inline;
      padding: 0 0.2em;
      margin: 20px 0;
      font-weight: lighter;
      color: rgb(150, 150, 150);
      margin-bottom: 15px;
      // &:not(:first-child)::before {
      //   content: " | ";
      // }
    }
    #selected-project-role {
      font-size: 11pt;
      color: rgb(150, 150, 150);
      margin: 20px 0;
      font-weight: lighter;
      margin-bottom: 15px;
    }
    #site-link {
      margin: 0em 20px;
      font-size: 11pt;
      font-weight: medium;
      letter-spacing: 0.01cm;
      text-align: justify;
      font-family: $primary-font;
      outline: none;
      border: none;
      padding: 0.5rem 1rem;
      background-color: $secondary-font;
      color: white;
      &:hover {
        background-color: transparent;
        transition: 0.3s;
      }
    }
  }
}
