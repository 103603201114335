@import "./config.scss";
/*Desktop View*/

.header {
  display: flex;
  background-color: rgb(27, 36, 47);
  justify-content: space-between;
  align-items: center;
  padding: 0px 55px;
  height: 70px;
  font-family: $primary-font;
  color: white;
  text-decoration: none;
  border-bottom: 4px solid $primary-2-color;
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 3.5em;
    height: 3em;
  }
  .nav_options {
    padding-left: 55px;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 20px;
    list-style-type: none;
    cursor: pointer;
    font-weight: bolder;
    font-size: 1rem;

    .option {
      padding: 1em 1em;
      &:hover {
        border: 1px solid rgb(223, 220, 220);
        transition: 0.2s;
        background-color: $primary-2-color;
        font-weight: 800;
        border: none !important;
      }
    }
    .option_active {
      display: none;
      color: maroon;
      font-weight: bolder;
      color: white;
      border-bottom: 1px solid white;
    }
  }

  .mobile-option {
    display: none;
    transition: 0.2s;
  }
  .option :hover {
    color: white;
    transition: 0.2s;
    &:hover {
      color: $secondary-font;
    }
  }
  .mobile_menu {
    display: none;
  }

  @media (max-width: 777px) {
    /*Mobile View */
    .header {
      overflow-x: hidden;
      padding: 0px 10px;
    }

    .nav_options {
      display: flex;
      justify-content: center;
      margin: 0;
      width: 22.5em;
      overflow: hidden;
      height: auto;
      position: absolute;
      top: 70px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
    }

    .nav_options.active {
      background-color: $primary-2-color;
      left: 0;
      opacity: 1;
      transition: all 0.8s ease;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
    }

    .mobile_menu {
      padding: 10px 0 0 0;
      font-size: 35px;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 8vh;
      grid-gap: 40px;

      &:hover {
        border: 1px solid rgb(223, 220, 220);
        transition: 0.8s;
        background-color: white !important;
        color: $primary-2-color;
        outline: none;
        border: none;
      }
    }

    .mobile_menu {
      display: block;
    }
  }
}
.sticky_header {
  @extend .header;
  position: fixed;
  z-index: 9;
  width: 100%;
}
