@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700");
$primary-font: "Raleway", sans-serif;
$primary-font-bold: "Raleway", sans-serif;
$primary-color: rgb(37, 42, 52);
$secondary-font: rgb(227, 27, 109);
$primary-2-color: rgb(4, 194, 201);

@mixin button() {
  border: 2px solid white;
  font-family: $primary-font;
  letter-spacing: 0.04cm;
  background: transparent;
  transition: 0.2s;
  font-size: 12pt;
  outline: none;
  letter-spacing: 0.02cm;
  @content;
  &:hover {
    background-color: $primary-2-color;
    cursor: pointer;
  }
}

@mixin about-grids($margin, $grid-gap, $base, $max-size) {
  margin: $margin;
  display: grid;
  justify-content: space-around;
  grid-gap: $grid-gap;
  grid-template-columns: repeat(auto-fill, minmax($base, $max-size));
  @content;
}

@mixin modal-design() {
  width: 85%;
  margin: 0 auto;

  @media (min-width: 1000px) {
    width: 72%;
    @content;
  }
}
