@import "./config.scss";

body {
  overflow-x: hidden;

  #particles-js {
    background: radial-gradient(circle, $primary-color 0%, $primary-color);
    width: 100%;
    height: 100vh;
  }
  #welcome {
    text-align: center;
    position: absolute;
    background-color: rgba($primary-color, 0.85);
    display: flex;
    width: 100vw;
    padding: 0 7px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: $primary-font;
    top: 0;
    bottom: 0;
    letter-spacing: -0.01cm;
    font-weight: 800;
    font-size: clamp(0.5rem, 0.6rem + 1vw, 7rem);
    color: white;

    .scale_in_center {
      -webkit-animation: scale_in_center 1.5s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
      animation: scale_in_center 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s
        both;
    }

    @keyframes scale_in_center {
      0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
      }
      100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }
    }
    #view_work_btn {
      @include button();
      text-align: center;
      height: 3.6em;
      padding: 0 1.5em;
      color: white;
      display: flex;
      align-items: center;
      font-weight: medium;
      text-transform: capitalize;
      transition: 0.2s ease-in 0s;
      &:hover {
        transform: scale(1.01);
        border: none;
        letter-spacing: 0.02;
        #workbtn_logo {
          transform: rotate(-270deg);
          margin-top: -10px;
        }
      }
      #workbtn_logo {
        transition: transform 0.5s ease-in 0s;
      }
    }
  }
}
