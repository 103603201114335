@import "./config.scss";

#about_container {
  margin: 10vh 0;
  #main_heading_container {
    padding: 10vh 0 0 0;
    display: grid;
    place-items: center;
    #main_heading {
      color: #444649;
      font-family: $primary-font-bold;
      font-size: 25pt;
      text-transform: uppercase;
      font-weight: bolder;
    }
    #main_heading_bar {
      background: #0d3065;
      height: 4px;
      margin: 25px 0 60px 0;
      width: 70px;
    }
  }

  #design_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    #single-polygon-container {
      flex: 0 1 130px;
      height: auto;
      margin: 2rem 3em;
      #design-polygon {
        background-color: $primary-2-color;
        clip-path: polygon(
          25% 0%,
          75% 0%,
          100% 50%,
          75% 100%,
          25% 100%,
          0% 50%
        );
        display: flex;
        justify-content: center;
        height: 59%;
        width: 100%;
        align-items: center;
        #design-logo {
          margin-top: 0.15em;
          color: #fff;
          font-size: 42pt;
          width: 45%;
        }
      }

      #design-content {
        line-height: 3em;
        #design-title {
          color: #616161;
          font-size: 1.05em;
          text-align: center;
          font-weight: bolder;
          font-family: $primary-font-bold;
        }
        #design-description {
          color: #616161;
          text-align: center;
          line-height: 12pt !important;
          font-family: $primary-font;
          font-size: 0.8em;
          font-weight: 500;
          width: 110%;
          margin-left: -7px;
        }
      }
      @media (max-width: 468px) {
        margin: 2rem 1em;
      }
    }
  }

  #profile_section {
    margin: 8em 2em 5em 2em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 420px));
    justify-content: center;
    grid-gap: 100px 150px;

    @media (max-width: 450px) {
      grid-template-columns: repeat(auto-fit, minmax(80%, 95%));
      #profile-container > #profile-image {
        height: 33vh !important;
      }
      #skills-section {
        width: 100%;
        justify-self: center;
        @media (max-width: 400px) {
          width: 120% !important;
        }
      }
    }

    #profile-container {
      #profile-image {
        clip-path: polygon(
          25% 0%,
          75% 0%,
          100% 50%,
          75% 100%,
          25% 100%,
          0% 50%
        );
        height: 400px;
        width: auto;
        background-image: url(../assets/dummy-person.png);
        background-size: 100% 120%;
        background-color: $primary-2-color;
        background-repeat: no-repeat;
        // background-position: center;
      }
      #profile-introduction {
        margin: 5vh 0 0 0;
        #who-this {
          color: #616161;
          font-family: $primary-font-bold;
          line-height: 18pt;
          text-align: center;
          font-size: 25px;
          font-weight: bolder;
        }
        #who-this-description {
          color: #616161;
          line-height: 18pt;
          padding: 15px 0;
          font-family: $primary-font;
          text-align: justify;
          font-size: 0.85em !important;
          font-weight: 500;
        }
      }
    }

    #skills-section {
      display: flex;
      // height: 525px;
      width: 100%;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      @media (max-width: 1090px) {
        margin-top: -50px;
      }
      #skill-bar {
        background-color: #eee;
        padding: 5px 13px 0 0;
        flex: auto;
        height: 30px;
        margin: 5px 0;
        width: auto;
        font-family: $primary-font;
        font-weight: 600;
        color: black;
        text-align: right;
        font-size: 11pt;
      }
      #skill-width {
        height: 30px;
        background-color: #00a1a7;
      }
      #skill-width-animation {
        @extend #skill-width;
        animation: example 1.5s linear 3s 2;
        animation-fill-mode: forwards;
        @keyframes example {
          0% {
            background-color: #00a1a7;
            width: 50px;
          }
          25% {
            background-color: #00a1a7;
            width: 100px;
          }
          50% {
            background-color: #00a1a7;
            width: 150px;
          }
          75% {
            background-color: #00a1a7;
            width: 200px;
          }
          100% {
            background-color: #00a1a7;
            width: 250px;
          }
        }
      }

      #skill-name {
        padding-top: 5px;
        background-color: #04c2c9;
        font-family: $primary-font-bold;
        font-weight: bolder;
        color: #fff;
        // width: clamp(22%, 27%, 27%);
        margin: 5px 0;

        width: 27%;
        overflow: auto;
        height: 30px;
        font-size: 11pt;
        text-align: center;
      }
      @media (max-width: 500px) {
        skill-bar {
          font-size: 9pt;
          padding: 7px;
          font-family: Verdana, Geneva, Tahoma, sans-serif;
        }
        #skill-name {
          font-size: 9pt;
          padding: 7px;
        }
      }
    }
  }
}
