@import "./config.scss";
@import "./about.scss";

#blogs-container {
  @extend #about_container;
  padding: 0 0 7vh 0;

  #heading_container {
    @extend #main_heading_container;

    #heading {
      @extend #main_heading;
    }
    #heading_bar {
      @extend #main_heading_bar;
      margin-bottom: 1.5em;
    }
  }
  #blogs-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.4rem;

    #blogs-inner-section {
      #blog-image {
        width: 380px;
        height: auto;
      }

      @media (max-width: 880px) and (min-width: 750px) {
        #blog-image {
          width: 320px;
          height: auto;
        }
      }
      @media (max-width: 600px) {
        #blog-image {
          width: 92vw;
          height: auto;
        }
      }
    }
    #blog-title {
      padding-top: 4vh;
      font-family: $primary-font-bold;
      font-size: 15pt;
      color: #616161;
      transition: 0.2s;
      &:hover {
        color: $primary-2-color;
        cursor: pointer;
      }
    }
    #blog-bar {
      background: #04c2c9;
      height: 3px;
      margin: 10px 0px;
      width: 23%;
    }
    #blog-date {
      padding-top: 1vh;
      font-family: $primary-font;
      font-weight: lighter;
      color: #616161;
      font-size: 10pt;
    }
    #blog-description {
      padding-top: 2vh;
      font-weight: medium;
      font-size: 0.83em;
      text-align: justify;
      font-family: $primary-font;
    }
  }
}
