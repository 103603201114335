@import "./config.scss";
@import "./about.scss";

#contact_container {
  margin: 0px 0 6vh 0;
  clip-path: polygon(0 0, 51% 13%, 100% 0, 100% 100%, 0 100%, 0 49%);
  background-color: rgb(37, 41, 52);
  height: 900px;
  padding: 6vh 0;
  #heading_container {
    margin-top: 130px;
    display: grid;
    place-items: center;
    #heading {
      color: #ffffff;
      font-family: $primary-font-bold;
      font-size: 25pt;
      text-transform: uppercase;
      font-weight: bolder;
    }
    #heading_bar {
      background: #ffffff;
      height: 4px;
      margin: 25px 0 40px 0;
      width: 70px;
    }
    #contact_sub_heading {
      color: $primary-2-color;
      font-family: $primary-font;
      font-weight: bolder;
      font-size: 16pt;
    }
    @media (max-width: 600px) {
      #contact_sub_heading {
        font-size: 4vw;
      }
    }
  }
  #form-container {
    margin-block: 35pt 10pt;
    display: grid;
    place-items: center;

    #input_field {
      outline: none;
      padding-left: 1.5em;
      height: 6vh;
      font-style: $primary-font;
      margin-top: 2vh;
      width: 460px;
      color: $primary-2-color;
      caret-color: $primary-2-color;
      border: none;
      font-size: 15px;
      background-color: rgb(30, 36, 44);
      transition: 0.4s;
      &:focus {
        outline: 1px solid $primary-2-color;
      }
    }

    #message {
      @extend #input_field;
      height: 16vh;
      padding-top: 2vh;
      resize: none;
    }
    #form_submit_btn {
      margin-top: 1.5vh;
      @include button();
      float: right;
      border: 1px solid rgb(255, 255, 255);
      color: white;
      text-transform: uppercase;
      padding: 1em;
    }
    @media (max-width: 600px) {
      #input_field {
        width: 75vw;
      }
      #form_submit_btn {
        padding: 2vw;
      }
    }
    @media (max-width: 900px) {
      #form_submit_btn {
        padding: 1.5vw;
      }
    }
  }
}
