* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  user-select: none;
  overflow-x: hidden;
}
