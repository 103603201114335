@import "./config.scss";
#footer-container {
  margin-top: -6vh;
  padding: 15vh 0 10vh 0;
  display: grid;
  background-color: rgb(27, 36, 47);
  place-items: center;
  #icons-container {
    display: flex;
    width: 300px;
    height: 50px;
    justify-content: space-around;
    #social-icon {
      color: white;
      display: flex;
      padding: 0.2em 0.4em 0 0.4em;
      align-items: center;
      background-color: rgb(38, 47, 56);
      font-size: 20pt;
    }
  }
  #footnote {
    color: #8f9aa7;
    font-size: 10pt;
    margin-top: 5vh;
    opacity: 0.6;
    font-family: $primary-font;
    font-weight: light;
  }
}
